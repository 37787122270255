import { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Icon } from "@iconify-icon/react";
import SiteSearchBar from "./SiteSearchBar";
import * as styles from "./LogoBanner.module.scss";

const sections = [
  { name: "Updates", path: "/updates/", icon: "mdi:bell-ring-outline" },
  { name: "Student", path: "/student/", icon: "mdi:school-outline" },
  {
    name: "Staff",
    path: "/staff/",
    icon: "mdi:badge-account-horizontal-outline",
  },
  { name: "Parent", path: "/parent/", icon: "mdi:account-child-circle" },
];

export default function LogoBanner() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Navbar>
        <Container>
          <Navbar.Brand as={Link} to="/">
            <StaticImage
              alt="Varndean College"
              src="../images/logos/logo.png"
              placeholder="none"
            />
          </Navbar.Brand>
          <Nav>
            {sections.map((section) => (
              <Nav.Item
                key={section.path}
                className={`d-none d-lg-block ${styles.icon}`}
              >
                <Nav.Link as={Link} to={section.path}>
                  <Icon inline icon={section.icon} height={20} /> {section.name}
                </Nav.Link>
              </Nav.Item>
            ))}
            <Nav.Item className={styles.icon}>
              <Nav.Link onClick={() => setIsOpen(!isOpen)}>
                <div className="d-none d-lg-block">
                  <Icon inline icon="mdi:magnify" width={20} /> Search
                </div>
                <div className="d-lg-none">
                  <Icon inline icon="mdi:magnify" width={36} />
                </div>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Container>
      </Navbar>
      <Navbar.Collapse in={isOpen}>
        <SiteSearchBar />
      </Navbar.Collapse>
    </>
  );
}
