import clsx from "clsx";
import * as styles from "./ThickRule.module.scss";

export default function ThickRule({ slim, dotted, className }) {
  const classes = clsx(className, {
    [styles.regularMargins]: !slim,
    [styles.slimMargins]: slim,
    [styles.solid]: !dotted,
    [styles.dotted]: dotted,
  });

  return <hr className={classes} />;
}
