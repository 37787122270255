import { Icon } from "@iconify-icon/react";
import { Link } from "gatsby";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import ThickRule from "./ThickRule";
import * as styles from "./NavbarItems.module.scss";

const about = [
  { name: "Principal's Welcome", path: "/college/" },
  { name: "Meet the Management Team", path: "/management/" },
  { name: "College Tour & Videos", path: "/videos/" },
  { name: "Prospectus & Brochures", path: "/brochures/" },
  { name: "Mission & Values", path: "/values/" },
  { name: "Sustainability", path: "https://sites.google.com/varndean.ac.uk/sustainability/home" },
  { name: "Exam Results", path: "/exams/" },
  { name: "Exam Information & Policies", path: "/exam_info/" },
  { name: "Sam Paterson Award", path: "/paterson_award/" },
  { name: "UAL Future Creatives", path: "/ual/" },
  { name: "Alumni", path: "/alumni/" },
  { name: "Testimonials", path: "/testimonials/" },
  { name: "Governors", path: "/governors/" },
  { name: "Policies", path: "/policies/" },
  { name: "FAQ", path: "/faq/" },
  { name: "Working for Us", path: "/vacancies/" },
  { name: "Getting to Varndean", path: "/travel/" },
  { name: "Contact Us", path: "/contact/" },
];

const support = [
  {
    name: "Report A Concern Anonymously",
    path: "https://docs.google.com/forms/d/e/1FAIpQLSf1PeRVXq27uCc-peyKVoLOS5OqS78r1zF9bWKL8J6PZDOdRA/viewform?vc=0&c=0&w=1&flr=0",
  },
  { name: "Additional Learning Support", path: "/support/" },
  { name: "EDI", path: "/edi/" },
  { name: "Learning Coaching", path: "/learning_coaching/" },
  { name: "Mentoring Information", path: "/mentoring/" },
  { name: "Safeguarding", path: "/safeguarding/" },
  { name: "Therapy Team", path: "/counselling/" },
  { name: "Welfare & Financial Information", path: "/welfare/" },
  { name: "Out of Hours Support", path: "/documents/Support.pdf" },
  { name: "Wellbeing", path: "/wellbeing/" },
];

const admissions = [
  { name: "Application Process", path: "/apply/" },
  { name: "Prospectus & Brochures", path: "/brochures/" },
  { name: "College Tour & Videos", path: "/videos/" },
  { name: "Entry Requirements", path: "/requirements/" },
  { name: "STEM at Varndean College", path: "/stem/" },
  { name: "Why Choose Varndean?", path: "/why_varndean_college/" },
  { name: "Year 11 Information", path: "/year11/" },
  { name: "Your Study Programme", path: "/applicants/" },
];

const news = [
  { name: "Latest News", path: "/news/" },
  { name: "Scheduled Events", path: "/events/" },
  { name: "Varndean Update", path: "/update/" },
];

const inter = [
  { name: "How to Apply", path: "/international/" },
  { name: "Host Families", path: "/host_families/" },
];

const student = [
  { name: "British Values at Varndean", path: "/british_values/" },
  { name: "College Library", path: "/library/" },
  {
    name: "Enrichment Activities",
    path: "https://sites.google.com/varndean.ac.uk/varndean-values/home",
  },
  { 
    name: "IT Resources",
    path: "https://sites.google.com/varndean.ac.uk/byod2024/home"
  },
  { name: "Learning Coaching", path: "/learning_coaching/" },
  { name: "STEM at Varndean College", path: "/stem/" },
  { name: "Student Union", path: "/student_union/" },
  { name: "Tutorial", path: "/tutorial/" },
];

const careers = [
  { name: "Meet the Team", path: "/careers/" },
  { name: "Measuring Success", path: "/careers/success/" },
  { name: "Aspire Programme", path: "/stretch/" },
  { name: "For Students", path: "/careers/students/" },
  { name: "For Parents", path: "/careers/parents/" },
  { name: "For Staff", path: "/careers/staff/" },
  { name: "For Employers", path: "/careers/employers/" },
  { name: "UCAS for Ex Students", path: "/ucas/" },
  // { name: "Resources & Websites", path: "/careers/resources/" },
];

function Dropdown({ title, id, items }) {
  return (
    <NavDropdown
      title={title}
      id={`${id}-dropdown`}
      className={title === "About" && styles.twoColumns}
    >
      {items.map((item) => (
        <NavDropdown.Item key={item.name} as={Link} to={item.path}>
          {item.name}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
}

function MobileOnly() {
  const items = [
    { name: "Updates", path: "/updates/", icon: "mdi:bell-ring-outline" },
    { name: "Student", path: "/student/", icon: "mdi:school-outline" },
    {
      name: "Staff",
      path: "/staff/",
      icon: "mdi:badge-account-horizontal-outline",
    },
    { name: "Parent", path: "/parent/", icon: "mdi:account-child-circle" },
  ];

  return (
    <div className="d-lg-none">
      {items.map((item) => (
        <Nav.Link as={Link} to={item.path} key={item.name}>
          <Icon inline icon={item.icon} height={20} /> {item.name}
        </Nav.Link>
      ))}
      <ThickRule style={{ color: "#fff" }} />
    </div>
  );
}

export default function NavbarItems() {
  return (
    <Nav className="mx-auto p-2 p-xl-0">
      <MobileOnly />
      <Dropdown title="About" id="about" items={about} />
      <Dropdown title="Admissions" id="admissions" items={admissions} />
      <Nav.Link as={Link} to="/adulted/">
        Adult Education
      </Nav.Link>
      <Dropdown title="Careers" id="careers" items={careers} />
      <Nav.Link as={Link} to="/courses/">
        Courses
      </Nav.Link>
      <Dropdown title="International" id="international" items={inter} />
      <Dropdown title="News & Events" id="news" items={news} />
      <Dropdown title="Safeguarding & Support" id="support" items={support} />
      <Dropdown title="Student Experience" id="student" items={student} />
    </Nav>
  );
}
