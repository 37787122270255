exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-absence-jsx": () => import("./../../../src/pages/absence.jsx" /* webpackChunkName: "component---src-pages-absence-jsx" */),
  "component---src-pages-accessibility-jsx": () => import("./../../../src/pages/accessibility.jsx" /* webpackChunkName: "component---src-pages-accessibility-jsx" */),
  "component---src-pages-adulted-courses-jsx": () => import("./../../../src/pages/adulted/courses.jsx" /* webpackChunkName: "component---src-pages-adulted-courses-jsx" */),
  "component---src-pages-adulted-enrol-jsx": () => import("./../../../src/pages/adulted/enrol.jsx" /* webpackChunkName: "component---src-pages-adulted-enrol-jsx" */),
  "component---src-pages-adulted-faq-jsx": () => import("./../../../src/pages/adulted/faq.jsx" /* webpackChunkName: "component---src-pages-adulted-faq-jsx" */),
  "component---src-pages-adulted-index-jsx": () => import("./../../../src/pages/adulted/index.jsx" /* webpackChunkName: "component---src-pages-adulted-index-jsx" */),
  "component---src-pages-adulted-news-jsx": () => import("./../../../src/pages/adulted/news.jsx" /* webpackChunkName: "component---src-pages-adulted-news-jsx" */),
  "component---src-pages-adulted-success-jsx": () => import("./../../../src/pages/adulted/success.jsx" /* webpackChunkName: "component---src-pages-adulted-success-jsx" */),
  "component---src-pages-adulted-surveys-jsx": () => import("./../../../src/pages/adulted/surveys.jsx" /* webpackChunkName: "component---src-pages-adulted-surveys-jsx" */),
  "component---src-pages-adulted-welfare-jsx": () => import("./../../../src/pages/adulted/welfare.jsx" /* webpackChunkName: "component---src-pages-adulted-welfare-jsx" */),
  "component---src-pages-alumni-jsx": () => import("./../../../src/pages/alumni.jsx" /* webpackChunkName: "component---src-pages-alumni-jsx" */),
  "component---src-pages-applicants-jsx": () => import("./../../../src/pages/applicants.jsx" /* webpackChunkName: "component---src-pages-applicants-jsx" */),
  "component---src-pages-apply-jsx": () => import("./../../../src/pages/apply.jsx" /* webpackChunkName: "component---src-pages-apply-jsx" */),
  "component---src-pages-arts-alumni-jsx": () => import("./../../../src/pages/arts_alumni.jsx" /* webpackChunkName: "component---src-pages-arts-alumni-jsx" */),
  "component---src-pages-british-values-jsx": () => import("./../../../src/pages/british_values.jsx" /* webpackChunkName: "component---src-pages-british-values-jsx" */),
  "component---src-pages-brochures-jsx": () => import("./../../../src/pages/brochures.jsx" /* webpackChunkName: "component---src-pages-brochures-jsx" */),
  "component---src-pages-calendar-jsx": () => import("./../../../src/pages/calendar.jsx" /* webpackChunkName: "component---src-pages-calendar-jsx" */),
  "component---src-pages-careers-employers-jsx": () => import("./../../../src/pages/careers/employers.jsx" /* webpackChunkName: "component---src-pages-careers-employers-jsx" */),
  "component---src-pages-careers-index-jsx": () => import("./../../../src/pages/careers/index.jsx" /* webpackChunkName: "component---src-pages-careers-index-jsx" */),
  "component---src-pages-careers-parents-jsx": () => import("./../../../src/pages/careers/parents.jsx" /* webpackChunkName: "component---src-pages-careers-parents-jsx" */),
  "component---src-pages-careers-staff-jsx": () => import("./../../../src/pages/careers/staff.jsx" /* webpackChunkName: "component---src-pages-careers-staff-jsx" */),
  "component---src-pages-careers-students-jsx": () => import("./../../../src/pages/careers/students.jsx" /* webpackChunkName: "component---src-pages-careers-students-jsx" */),
  "component---src-pages-careers-success-jsx": () => import("./../../../src/pages/careers/success.jsx" /* webpackChunkName: "component---src-pages-careers-success-jsx" */),
  "component---src-pages-college-jsx": () => import("./../../../src/pages/college.jsx" /* webpackChunkName: "component---src-pages-college-jsx" */),
  "component---src-pages-construction-jsx": () => import("./../../../src/pages/construction.jsx" /* webpackChunkName: "component---src-pages-construction-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-counselling-jsx": () => import("./../../../src/pages/counselling.jsx" /* webpackChunkName: "component---src-pages-counselling-jsx" */),
  "component---src-pages-course-start-jsx": () => import("./../../../src/pages/course_start.jsx" /* webpackChunkName: "component---src-pages-course-start-jsx" */),
  "component---src-pages-courses-3-d-design-jsx": () => import("./../../../src/pages/courses/3d_design.jsx" /* webpackChunkName: "component---src-pages-courses-3-d-design-jsx" */),
  "component---src-pages-courses-adult-[id]-jsx": () => import("./../../../src/pages/courses/adult/[id].jsx" /* webpackChunkName: "component---src-pages-courses-adult-[id]-jsx" */),
  "component---src-pages-courses-applied-psychology-jsx": () => import("./../../../src/pages/courses/applied_psychology.jsx" /* webpackChunkName: "component---src-pages-courses-applied-psychology-jsx" */),
  "component---src-pages-courses-art-fine-jsx": () => import("./../../../src/pages/courses/art_fine.jsx" /* webpackChunkName: "component---src-pages-courses-art-fine-jsx" */),
  "component---src-pages-courses-art-gcse-jsx": () => import("./../../../src/pages/courses/art_gcse.jsx" /* webpackChunkName: "component---src-pages-courses-art-gcse-jsx" */),
  "component---src-pages-courses-art-history-jsx": () => import("./../../../src/pages/courses/art_history.jsx" /* webpackChunkName: "component---src-pages-courses-art-history-jsx" */),
  "component---src-pages-courses-art-textiles-jsx": () => import("./../../../src/pages/courses/art_textiles.jsx" /* webpackChunkName: "component---src-pages-courses-art-textiles-jsx" */),
  "component---src-pages-courses-biology-applied-jsx": () => import("./../../../src/pages/courses/biology_applied.jsx" /* webpackChunkName: "component---src-pages-courses-biology-applied-jsx" */),
  "component---src-pages-courses-biology-jsx": () => import("./../../../src/pages/courses/biology.jsx" /* webpackChunkName: "component---src-pages-courses-biology-jsx" */),
  "component---src-pages-courses-business-gcse-jsx": () => import("./../../../src/pages/courses/business_gcse.jsx" /* webpackChunkName: "component---src-pages-courses-business-gcse-jsx" */),
  "component---src-pages-courses-business-studies-jsx": () => import("./../../../src/pages/courses/business_studies.jsx" /* webpackChunkName: "component---src-pages-courses-business-studies-jsx" */),
  "component---src-pages-courses-business-studies-voc-jsx": () => import("./../../../src/pages/courses/business_studies_voc.jsx" /* webpackChunkName: "component---src-pages-courses-business-studies-voc-jsx" */),
  "component---src-pages-courses-chemistry-jsx": () => import("./../../../src/pages/courses/chemistry.jsx" /* webpackChunkName: "component---src-pages-courses-chemistry-jsx" */),
  "component---src-pages-courses-children-care-jsx": () => import("./../../../src/pages/courses/children_care.jsx" /* webpackChunkName: "component---src-pages-courses-children-care-jsx" */),
  "component---src-pages-courses-childrens-play-l-2-jsx": () => import("./../../../src/pages/courses/childrens_play_l2.jsx" /* webpackChunkName: "component---src-pages-courses-childrens-play-l-2-jsx" */),
  "component---src-pages-courses-classical-civilisation-jsx": () => import("./../../../src/pages/courses/classical_civilisation.jsx" /* webpackChunkName: "component---src-pages-courses-classical-civilisation-jsx" */),
  "component---src-pages-courses-clq-jsx": () => import("./../../../src/pages/courses/clq.jsx" /* webpackChunkName: "component---src-pages-courses-clq-jsx" */),
  "component---src-pages-courses-computing-a-jsx": () => import("./../../../src/pages/courses/computing_a.jsx" /* webpackChunkName: "component---src-pages-courses-computing-a-jsx" */),
  "component---src-pages-courses-creative-3-jsx": () => import("./../../../src/pages/courses/creative_3.jsx" /* webpackChunkName: "component---src-pages-courses-creative-3-jsx" */),
  "component---src-pages-courses-creative-media-l-2-jsx": () => import("./../../../src/pages/courses/creative_media_l2.jsx" /* webpackChunkName: "component---src-pages-courses-creative-media-l-2-jsx" */),
  "component---src-pages-courses-criminology-jsx": () => import("./../../../src/pages/courses/criminology.jsx" /* webpackChunkName: "component---src-pages-courses-criminology-jsx" */),
  "component---src-pages-courses-dance-jsx": () => import("./../../../src/pages/courses/dance.jsx" /* webpackChunkName: "component---src-pages-courses-dance-jsx" */),
  "component---src-pages-courses-drama-theatre-jsx": () => import("./../../../src/pages/courses/drama_theatre.jsx" /* webpackChunkName: "component---src-pages-courses-drama-theatre-jsx" */),
  "component---src-pages-courses-economics-jsx": () => import("./../../../src/pages/courses/economics.jsx" /* webpackChunkName: "component---src-pages-courses-economics-jsx" */),
  "component---src-pages-courses-engineering-jsx": () => import("./../../../src/pages/courses/engineering.jsx" /* webpackChunkName: "component---src-pages-courses-engineering-jsx" */),
  "component---src-pages-courses-englang-lit-jsx": () => import("./../../../src/pages/courses/englang_lit.jsx" /* webpackChunkName: "component---src-pages-courses-englang-lit-jsx" */),
  "component---src-pages-courses-english-gcse-jsx": () => import("./../../../src/pages/courses/english_gcse.jsx" /* webpackChunkName: "component---src-pages-courses-english-gcse-jsx" */),
  "component---src-pages-courses-english-language-jsx": () => import("./../../../src/pages/courses/english_language.jsx" /* webpackChunkName: "component---src-pages-courses-english-language-jsx" */),
  "component---src-pages-courses-english-lit-jsx": () => import("./../../../src/pages/courses/english_lit.jsx" /* webpackChunkName: "component---src-pages-courses-english-lit-jsx" */),
  "component---src-pages-courses-environmental-studies-jsx": () => import("./../../../src/pages/courses/environmental_studies.jsx" /* webpackChunkName: "component---src-pages-courses-environmental-studies-jsx" */),
  "component---src-pages-courses-epq-jsx": () => import("./../../../src/pages/courses/epq.jsx" /* webpackChunkName: "component---src-pages-courses-epq-jsx" */),
  "component---src-pages-courses-esol-jsx": () => import("./../../../src/pages/courses/esol.jsx" /* webpackChunkName: "component---src-pages-courses-esol-jsx" */),
  "component---src-pages-courses-film-studies-jsx": () => import("./../../../src/pages/courses/film_studies.jsx" /* webpackChunkName: "component---src-pages-courses-film-studies-jsx" */),
  "component---src-pages-courses-french-jsx": () => import("./../../../src/pages/courses/french.jsx" /* webpackChunkName: "component---src-pages-courses-french-jsx" */),
  "component---src-pages-courses-geography-jsx": () => import("./../../../src/pages/courses/geography.jsx" /* webpackChunkName: "component---src-pages-courses-geography-jsx" */),
  "component---src-pages-courses-german-jsx": () => import("./../../../src/pages/courses/german.jsx" /* webpackChunkName: "component---src-pages-courses-german-jsx" */),
  "component---src-pages-courses-graphic-design-jsx": () => import("./../../../src/pages/courses/graphic_design.jsx" /* webpackChunkName: "component---src-pages-courses-graphic-design-jsx" */),
  "component---src-pages-courses-health-social-jsx": () => import("./../../../src/pages/courses/health_social.jsx" /* webpackChunkName: "component---src-pages-courses-health-social-jsx" */),
  "component---src-pages-courses-health-social-l-2-jsx": () => import("./../../../src/pages/courses/health_social_l2.jsx" /* webpackChunkName: "component---src-pages-courses-health-social-l-2-jsx" */),
  "component---src-pages-courses-history-early-jsx": () => import("./../../../src/pages/courses/history_early.jsx" /* webpackChunkName: "component---src-pages-courses-history-early-jsx" */),
  "component---src-pages-courses-history-modern-jsx": () => import("./../../../src/pages/courses/history_modern.jsx" /* webpackChunkName: "component---src-pages-courses-history-modern-jsx" */),
  "component---src-pages-courses-i-bacc-jsx": () => import("./../../../src/pages/courses/i_bacc.jsx" /* webpackChunkName: "component---src-pages-courses-i-bacc-jsx" */),
  "component---src-pages-courses-ict-applied-jsx": () => import("./../../../src/pages/courses/ict_applied.jsx" /* webpackChunkName: "component---src-pages-courses-ict-applied-jsx" */),
  "component---src-pages-courses-index-jsx": () => import("./../../../src/pages/courses/index.jsx" /* webpackChunkName: "component---src-pages-courses-index-jsx" */),
  "component---src-pages-courses-interactive-media-jsx": () => import("./../../../src/pages/courses/interactive_media.jsx" /* webpackChunkName: "component---src-pages-courses-interactive-media-jsx" */),
  "component---src-pages-courses-law-applied-jsx": () => import("./../../../src/pages/courses/law_applied.jsx" /* webpackChunkName: "component---src-pages-courses-law-applied-jsx" */),
  "component---src-pages-courses-law-jsx": () => import("./../../../src/pages/courses/law.jsx" /* webpackChunkName: "component---src-pages-courses-law-jsx" */),
  "component---src-pages-courses-mathematics-gcse-jsx": () => import("./../../../src/pages/courses/mathematics_gcse.jsx" /* webpackChunkName: "component---src-pages-courses-mathematics-gcse-jsx" */),
  "component---src-pages-courses-mathematics-jsx": () => import("./../../../src/pages/courses/mathematics.jsx" /* webpackChunkName: "component---src-pages-courses-mathematics-jsx" */),
  "component---src-pages-courses-media-studies-jsx": () => import("./../../../src/pages/courses/media_studies.jsx" /* webpackChunkName: "component---src-pages-courses-media-studies-jsx" */),
  "component---src-pages-courses-music-tech-jsx": () => import("./../../../src/pages/courses/music_tech.jsx" /* webpackChunkName: "component---src-pages-courses-music-tech-jsx" */),
  "component---src-pages-courses-p-arts-acting-jsx": () => import("./../../../src/pages/courses/p_arts_acting.jsx" /* webpackChunkName: "component---src-pages-courses-p-arts-acting-jsx" */),
  "component---src-pages-courses-performing-arts-l-2-jsx": () => import("./../../../src/pages/courses/performing_arts_l2.jsx" /* webpackChunkName: "component---src-pages-courses-performing-arts-l-2-jsx" */),
  "component---src-pages-courses-philosophy-jsx": () => import("./../../../src/pages/courses/philosophy.jsx" /* webpackChunkName: "component---src-pages-courses-philosophy-jsx" */),
  "component---src-pages-courses-photography-gcse-jsx": () => import("./../../../src/pages/courses/photography_gcse.jsx" /* webpackChunkName: "component---src-pages-courses-photography-gcse-jsx" */),
  "component---src-pages-courses-photography-jsx": () => import("./../../../src/pages/courses/photography.jsx" /* webpackChunkName: "component---src-pages-courses-photography-jsx" */),
  "component---src-pages-courses-physical-edu-jsx": () => import("./../../../src/pages/courses/physical_edu.jsx" /* webpackChunkName: "component---src-pages-courses-physical-edu-jsx" */),
  "component---src-pages-courses-physics-jsx": () => import("./../../../src/pages/courses/physics.jsx" /* webpackChunkName: "component---src-pages-courses-physics-jsx" */),
  "component---src-pages-courses-politics-jsx": () => import("./../../../src/pages/courses/politics.jsx" /* webpackChunkName: "component---src-pages-courses-politics-jsx" */),
  "component---src-pages-courses-protective-services-jsx": () => import("./../../../src/pages/courses/protective_services.jsx" /* webpackChunkName: "component---src-pages-courses-protective-services-jsx" */),
  "component---src-pages-courses-protective-services-l-2-jsx": () => import("./../../../src/pages/courses/protective_services_l2.jsx" /* webpackChunkName: "component---src-pages-courses-protective-services-l-2-jsx" */),
  "component---src-pages-courses-psychology-jsx": () => import("./../../../src/pages/courses/psychology.jsx" /* webpackChunkName: "component---src-pages-courses-psychology-jsx" */),
  "component---src-pages-courses-science-forensic-jsx": () => import("./../../../src/pages/courses/science_forensic.jsx" /* webpackChunkName: "component---src-pages-courses-science-forensic-jsx" */),
  "component---src-pages-courses-science-jsx": () => import("./../../../src/pages/courses/science.jsx" /* webpackChunkName: "component---src-pages-courses-science-jsx" */),
  "component---src-pages-courses-sociology-gcse-jsx": () => import("./../../../src/pages/courses/sociology_gcse.jsx" /* webpackChunkName: "component---src-pages-courses-sociology-gcse-jsx" */),
  "component---src-pages-courses-sociology-jsx": () => import("./../../../src/pages/courses/sociology.jsx" /* webpackChunkName: "component---src-pages-courses-sociology-jsx" */),
  "component---src-pages-courses-spanish-jsx": () => import("./../../../src/pages/courses/spanish.jsx" /* webpackChunkName: "component---src-pages-courses-spanish-jsx" */),
  "component---src-pages-courses-sport-jsx": () => import("./../../../src/pages/courses/sport.jsx" /* webpackChunkName: "component---src-pages-courses-sport-jsx" */),
  "component---src-pages-courses-sport-l-2-jsx": () => import("./../../../src/pages/courses/sport_l2.jsx" /* webpackChunkName: "component---src-pages-courses-sport-l-2-jsx" */),
  "component---src-pages-courses-sports-gym-jsx": () => import("./../../../src/pages/courses/sports_gym.jsx" /* webpackChunkName: "component---src-pages-courses-sports-gym-jsx" */),
  "component---src-pages-courses-sports-leadership-jsx": () => import("./../../../src/pages/courses/sports_leadership.jsx" /* webpackChunkName: "component---src-pages-courses-sports-leadership-jsx" */),
  "component---src-pages-courses-sports-performance-jsx": () => import("./../../../src/pages/courses/sports_performance.jsx" /* webpackChunkName: "component---src-pages-courses-sports-performance-jsx" */),
  "component---src-pages-courses-travel-tourism-jsx": () => import("./../../../src/pages/courses/travel_tourism.jsx" /* webpackChunkName: "component---src-pages-courses-travel-tourism-jsx" */),
  "component---src-pages-courses-young-enterprise-jsx": () => import("./../../../src/pages/courses/young_enterprise.jsx" /* webpackChunkName: "component---src-pages-courses-young-enterprise-jsx" */),
  "component---src-pages-disclaimer-jsx": () => import("./../../../src/pages/disclaimer.jsx" /* webpackChunkName: "component---src-pages-disclaimer-jsx" */),
  "component---src-pages-edi-jsx": () => import("./../../../src/pages/edi.jsx" /* webpackChunkName: "component---src-pages-edi-jsx" */),
  "component---src-pages-events-9-ef-84051-g-6-jsx": () => import("./../../../src/pages/events/9ef84051g6.jsx" /* webpackChunkName: "component---src-pages-events-9-ef-84051-g-6-jsx" */),
  "component---src-pages-events-adulted-open-jsx": () => import("./../../../src/pages/events/adulted_open.jsx" /* webpackChunkName: "component---src-pages-events-adulted-open-jsx" */),
  "component---src-pages-events-building-2022-jsx": () => import("./../../../src/pages/events/building2022.jsx" /* webpackChunkName: "component---src-pages-events-building-2022-jsx" */),
  "component---src-pages-events-ib-talk-jsx": () => import("./../../../src/pages/events/ib_talk.jsx" /* webpackChunkName: "component---src-pages-events-ib-talk-jsx" */),
  "component---src-pages-events-index-jsx": () => import("./../../../src/pages/events/index.jsx" /* webpackChunkName: "component---src-pages-events-index-jsx" */),
  "component---src-pages-events-ncsqrytt-8-c-jsx": () => import("./../../../src/pages/events/ncsqrytt8c.jsx" /* webpackChunkName: "component---src-pages-events-ncsqrytt-8-c-jsx" */),
  "component---src-pages-events-open-days-jsx": () => import("./../../../src/pages/events/open_days.jsx" /* webpackChunkName: "component---src-pages-events-open-days-jsx" */),
  "component---src-pages-events-results-2019-jsx": () => import("./../../../src/pages/events/results2019.jsx" /* webpackChunkName: "component---src-pages-events-results-2019-jsx" */),
  "component---src-pages-events-results-2022-jsx": () => import("./../../../src/pages/events/results2022.jsx" /* webpackChunkName: "component---src-pages-events-results-2022-jsx" */),
  "component---src-pages-events-year-10-jsx": () => import("./../../../src/pages/events/year10.jsx" /* webpackChunkName: "component---src-pages-events-year-10-jsx" */),
  "component---src-pages-exam-info-jsx": () => import("./../../../src/pages/exam_info.jsx" /* webpackChunkName: "component---src-pages-exam-info-jsx" */),
  "component---src-pages-exams-jsx": () => import("./../../../src/pages/exams.jsx" /* webpackChunkName: "component---src-pages-exams-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-gov-confidentiality-jsx": () => import("./../../../src/pages/gov_confidentiality.jsx" /* webpackChunkName: "component---src-pages-gov-confidentiality-jsx" */),
  "component---src-pages-governors-jsx": () => import("./../../../src/pages/governors.jsx" /* webpackChunkName: "component---src-pages-governors-jsx" */),
  "component---src-pages-governors-minutes-jsx": () => import("./../../../src/pages/governors_minutes.jsx" /* webpackChunkName: "component---src-pages-governors-minutes-jsx" */),
  "component---src-pages-host-families-jsx": () => import("./../../../src/pages/host_families.jsx" /* webpackChunkName: "component---src-pages-host-families-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-international-jsx": () => import("./../../../src/pages/international.jsx" /* webpackChunkName: "component---src-pages-international-jsx" */),
  "component---src-pages-learning-coaching-jsx": () => import("./../../../src/pages/learning_coaching.jsx" /* webpackChunkName: "component---src-pages-learning-coaching-jsx" */),
  "component---src-pages-library-jsx": () => import("./../../../src/pages/library.jsx" /* webpackChunkName: "component---src-pages-library-jsx" */),
  "component---src-pages-management-jsx": () => import("./../../../src/pages/management.jsx" /* webpackChunkName: "component---src-pages-management-jsx" */),
  "component---src-pages-mentoring-jsx": () => import("./../../../src/pages/mentoring.jsx" /* webpackChunkName: "component---src-pages-mentoring-jsx" */),
  "component---src-pages-news-archives-[year]-jsx": () => import("./../../../src/pages/news/archives/[year].jsx" /* webpackChunkName: "component---src-pages-news-archives-[year]-jsx" */),
  "component---src-pages-news-index-jsx": () => import("./../../../src/pages/news/index.jsx" /* webpackChunkName: "component---src-pages-news-index-jsx" */),
  "component---src-pages-parent-jsx": () => import("./../../../src/pages/parent.jsx" /* webpackChunkName: "component---src-pages-parent-jsx" */),
  "component---src-pages-paterson-award-jsx": () => import("./../../../src/pages/paterson_award.jsx" /* webpackChunkName: "component---src-pages-paterson-award-jsx" */),
  "component---src-pages-policies-jsx": () => import("./../../../src/pages/policies.jsx" /* webpackChunkName: "component---src-pages-policies-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-requirements-jsx": () => import("./../../../src/pages/requirements.jsx" /* webpackChunkName: "component---src-pages-requirements-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-safeguarding-jsx": () => import("./../../../src/pages/safeguarding.jsx" /* webpackChunkName: "component---src-pages-safeguarding-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-staff-jsx": () => import("./../../../src/pages/staff/[...].jsx" /* webpackChunkName: "component---src-pages-staff-jsx" */),
  "component---src-pages-stem-jsx": () => import("./../../../src/pages/stem.jsx" /* webpackChunkName: "component---src-pages-stem-jsx" */),
  "component---src-pages-stretch-jsx": () => import("./../../../src/pages/stretch.jsx" /* webpackChunkName: "component---src-pages-stretch-jsx" */),
  "component---src-pages-student-jsx": () => import("./../../../src/pages/student.jsx" /* webpackChunkName: "component---src-pages-student-jsx" */),
  "component---src-pages-student-union-jsx": () => import("./../../../src/pages/student_union.jsx" /* webpackChunkName: "component---src-pages-student-union-jsx" */),
  "component---src-pages-support-jsx": () => import("./../../../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */),
  "component---src-pages-term-dates-jsx": () => import("./../../../src/pages/term_dates.jsx" /* webpackChunkName: "component---src-pages-term-dates-jsx" */),
  "component---src-pages-testimonials-jsx": () => import("./../../../src/pages/testimonials.jsx" /* webpackChunkName: "component---src-pages-testimonials-jsx" */),
  "component---src-pages-travel-jsx": () => import("./../../../src/pages/travel.jsx" /* webpackChunkName: "component---src-pages-travel-jsx" */),
  "component---src-pages-tutorial-jsx": () => import("./../../../src/pages/tutorial.jsx" /* webpackChunkName: "component---src-pages-tutorial-jsx" */),
  "component---src-pages-ual-jsx": () => import("./../../../src/pages/ual.jsx" /* webpackChunkName: "component---src-pages-ual-jsx" */),
  "component---src-pages-ucas-jsx": () => import("./../../../src/pages/ucas.jsx" /* webpackChunkName: "component---src-pages-ucas-jsx" */),
  "component---src-pages-update-jsx": () => import("./../../../src/pages/update.jsx" /* webpackChunkName: "component---src-pages-update-jsx" */),
  "component---src-pages-updates-[type]-[id]-jsx": () => import("./../../../src/pages/updates/[type]/[id].jsx" /* webpackChunkName: "component---src-pages-updates-[type]-[id]-jsx" */),
  "component---src-pages-updates-index-jsx": () => import("./../../../src/pages/updates/index.jsx" /* webpackChunkName: "component---src-pages-updates-index-jsx" */),
  "component---src-pages-vacancies-jsx": () => import("./../../../src/pages/vacancies.jsx" /* webpackChunkName: "component---src-pages-vacancies-jsx" */),
  "component---src-pages-values-jsx": () => import("./../../../src/pages/values.jsx" /* webpackChunkName: "component---src-pages-values-jsx" */),
  "component---src-pages-videos-jsx": () => import("./../../../src/pages/videos.jsx" /* webpackChunkName: "component---src-pages-videos-jsx" */),
  "component---src-pages-welfare-jsx": () => import("./../../../src/pages/welfare.jsx" /* webpackChunkName: "component---src-pages-welfare-jsx" */),
  "component---src-pages-wellbeing-jsx": () => import("./../../../src/pages/wellbeing.jsx" /* webpackChunkName: "component---src-pages-wellbeing-jsx" */),
  "component---src-pages-why-varndean-college-jsx": () => import("./../../../src/pages/why_varndean_college.jsx" /* webpackChunkName: "component---src-pages-why-varndean-college-jsx" */),
  "component---src-pages-year-11-jsx": () => import("./../../../src/pages/year11.jsx" /* webpackChunkName: "component---src-pages-year-11-jsx" */),
  "component---src-templates-news-jsx": () => import("./../../../src/templates/news.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */)
}

