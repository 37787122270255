import Header from "./Header";
import Footer from "./Footer";

// Global Styles
import "../styles/global.scss";

// Fonts
import "@fontsource/quicksand";
import "@fontsource/open-sans";

export default function DefaultLayout({ children }) {
  return (
    <>
      <Header />
      <main role="main">{children}</main>
      <Footer />
    </>
  );
}
