import { useState, useEffect } from "react";
import { globalHistory } from "@reach/router";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavbarItems from "./NavbarItems";

export default function MainNavbar() {
  const [open, setOpen] = useState(false);

  useEffect(
    () =>
      globalHistory.listen(({ action }) => {
        if (action === "PUSH") {
          setOpen(false);
        }
      }),
    []
  );

  return (
      <Navbar
        variant="dark"
        expand="xl"
        expanded={open}
        onToggle={() => setOpen(!open)}>
        <Container className="px-xl-0">
          <Navbar.Toggle aria-controls="main-navbar" />
          <Navbar.Collapse id="main-navbar">
            <NavbarItems />
          </Navbar.Collapse>
        </Container>
      </Navbar>
  );
}
