import SSRProvider from "react-bootstrap/SSRProvider";
import { SWRConfig } from "swr";
import { LazyMotion, domAnimation } from "framer-motion";
import Layout from "./src/components/Layout";

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export function wrapRootElement({ element }) {
  return (
    <SWRConfig
      value={{
        fetcher: (resource, init) =>
          fetch(resource, init).then((res) => res.json()),
      }}
    >
      <SSRProvider>
        <LazyMotion strict features={domAnimation}>
          {element}
        </LazyMotion>
      </SSRProvider>
    </SWRConfig>
  );
}
