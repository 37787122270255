import { useState } from "react";
import { navigate } from "gatsby";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Search from "./Search";

export default function SiteSearchBar() {
  const [search, setSearch] = useState("");

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xl={10}>
          <Search
            className="mb-3"
            placeholder="Search"
            value={search}
            onChange={(ev) => setSearch(ev.target.value)}
            onKeyDown={(ev) =>
              ev.key === "Enter" && navigate(`/search?q=${search}`)
            }
          />
        </Col>
      </Row>
    </Container>
  );
}
